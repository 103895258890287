import { useState } from 'react';
import styled from 'styled-components';

const DropdownContainer = styled.div`
  position: static;
  display: flex;
  width: calc(100% - 2em);
  white-space: nowrap;
  padding: 1em;
`;

const DropdownButton = styled.div`
  width: 100%;
  padding: calc(0.5em + 1.5px);
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
  box-shadow: var(--box-shadow-dark);
  z-index: 3;
  display: flex;
  flex-direction: row;
    justify-content: space-between;
    align-items: center;

  &.show {
  border-radius: 8px 8px 0 0;
  }
`;

const DropdownList = styled.div`
  position: absolute;
  width: calc(70vw - 3em + 1px);
  overflow-y: auto;
  background: white;
  border: none;
  border-top: none;
  display: none;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  color: #777;
  border-radius: 0 0 8px 8px;
  box-shadow: var(--box-shadow-light);


  &.show {
    display: block;
  }
`;

const DropdownItem = styled.div`
  padding: 1em;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: #333;
  }
`;

function Dropdown({ options=[], value, onChange, valueField, displayField, $primary = false, containerStyle, buttonStyle, listStyle, itemStyle }) {
    const [toggle, setToggle] = useState(false);

    const selectedOption = options.find(option => option[valueField] === value);

    const toggleDropdown = () => setToggle(!toggle);

    const handleSelect = (option) => {
      if ($primary) {
        onChange({ target: { value: option[valueField] } });
      } else {
        onChange(option[valueField]);
      }
        
        setToggle(false);
    };

    return (
        <DropdownContainer style={containerStyle}>
            <DropdownButton style={buttonStyle} onClick={toggleDropdown} className={toggle ? 'show' : ''}  >
                {selectedOption ? `${selectedOption[displayField]}` : 'Select an option'}
                <span className="material-symbols-outlined" style={{ fontSize: '2em', color: '#999', overflow: 'hidden', userSelect: 'none' }}>
                    keyboard_arrow_down
                </span>

            </DropdownButton>
            {toggle && (
                <DropdownList style={listStyle} className={toggle ? 'show' : ''}>
                    {options.map(option => (
                        <DropdownItem key={option[valueField]} style={itemStyle} onClick={() => handleSelect(option)}>
                            {option[displayField]}
                        </DropdownItem>
                    ))}
                </DropdownList>
            )}
        </DropdownContainer>
    );
}

export default Dropdown;