import styled, { css } from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5em 0;
    
    ${props => props.$primary && css`
      flex-direction: column;
      justify-content: center;
        `}
`;

const Label = styled.label`
    width: 10em;
    padding: 0.5em;
    font-size: 1.1em;
    font-weight: 500;
    color: #333;
`;

const InputForm = styled.input`
    width: 100%;
    padding: 10px 15px;
    border-radius: 30px;
    background: #d9d9d98a;
    color: black;
    border-radius: 12px;
    box-sizing: border-box;
    border: 2px solid transparent;
    font-family: "Roboto", sans-serif;

    &:focus {
    border-color: #66272E;
    background-color: white;
    color: black;
    outline: none;
    }
`;

// InputWrapper to manage different input types including textarea
const InputWrapper = styled.input.attrs(props => ({
  type: props.type || 'text'  // Default type is text
}))`
  width: 100%;
  padding: 1.5vh 2em;
  border-radius: 30px;
  background: #d9d9d98a;
  color: white;
  box-sizing: border-box;
  border: 2px solid transparent;
  font-family: var(--font-body);
  font-size: var(--font-size-medium);

  &::placeholder {
    color: white;
    font-weight: 300;
    opacity: 1;
  }

  &:focus {
    background: #20202000;
  }
`;

// Extending the InputWrapper for a textarea specifically
const TextareaWrapper = styled(InputWrapper).attrs({
  as: 'textarea'  // Renders as a textarea instead of input
})`
  height: 15vh;

  ${props => props.$primary && css`
    width: 100%;
    height: 20vh;
    border-radius: 12px;
    padding: 1em;
    color: black;
    `}

      &:focus {
      border-color: #66272E;
  }

`;


const InputComponent = ({ type, $primary, ...props }) => {
  if ($primary) {
    // If $primary is true, wrap in Container
    return (
      <Container $primary={$primary}>
        <Label $primary={$primary}>{props.label}</Label>
        {type === 'textarea' ?
          <TextareaWrapper $primary={$primary} {...props} /> :
          <InputForm type={type} {...props} />
        }
      </Container>
    );
  } else {
    // If $primary is false, render without Container
    if (type === 'textarea') {
      return <TextareaWrapper {...props} />;
    }
    return <InputWrapper type={type} {...props} />;
  }
};

export default InputComponent;