import styled from 'styled-components';
import mq from '../assets/MQU.png';

const Container = styled.div`
    background-color: #66272E;
    padding: 0.5em 15vw;
    height: 15vh;
    color: white;
    display: flex;
    align-items: flex-end;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-transform: capitalize;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const titleStyle = {
    fontSize: 'var(--font-size-header)',
    fontWeight: 'var(--font-weight-bold)',
};

const subtitleStyle = {
    textTransform: 'none'
};

const PageTitle = ({ title, $primary, subtitle }) => {
    return (
        <Container>
            {$primary ? (
                <Content>
                    <div>
                        <h1 style={titleStyle}>{title}</h1>
                        <p style={subtitleStyle}>{subtitle}</p>
                    </div>
                    <Image src={mq} alt="MQU Logo" />
                </Content>
            ) : (
                <Content>
                    <h1 style={titleStyle}>{title}</h1>
                    <Image src={mq} alt="MQU Logo" />
                </Content>
            )}
        </Container>
    );
}

export default PageTitle;