// src/pages/MyReviewsPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Button from "../components/Button"
import PageTitle from '../components/PageTitle';

function MyReviewsPage() {
  const [reviews, setReviews] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("User in MyReviewsPage:", user);
    if (!user) {
      console.log("Redirecting to login because user is not authenticated.");
      navigate('/login');
    } else {
      fetchMyReviews();
    }
  }, [user, navigate]);

  const fetchMyReviews = () => {
    axios.get('/users/my-reviews', { withCredentials: true })
      .then(response => {
        setReviews(response.data);
      })
      .catch(error => {
        console.error('Error fetching reviews:', error);
      });
  };

  return (
    <main>
      <PageTitle title="My Account" $primary={true} subtitle={user && user.email ? user.email : undefined}/>
      <article className="content" id="mypage">
        <section>
          <div className="my-links">
            <Link to="/add-course">
              <Button $primary={false} label="Add New Course" />
            </Link>
            <Link to="/add-review">
              <Button $primary={false} $buttonColor="#76232F" $textColor="white" label="Add New Review" />
            </Link>
          </div>
          <h5 className="form-title" id="my-title">My Reviews</h5>
          <div>
            <ul>
              {reviews.length > 0 ? (
                reviews.map(review => (
                  <li key={review._id} className='my-review'>
                    <h2>{review.courseCode}: {review.courseName}</h2>
                    <p>{review.text}</p>
                    <p>Overall Rating: {review.overallRating}</p>
                    {/* Display other ratings if desired */}
                  </li>
                ))
              ) : (
                <p className="info-msg">You don't have any reviews yet.</p>
              )}
            </ul>
          </div>
        </section>
        <section>
          <h5 className="form-title">My Courses</h5>
          <p className="info-msg">Select an interesting course and mark as favorite!</p>
        </section>
      </article>
    </main>
  );
}

export default MyReviewsPage;
