// src/pages/RegisterPage.js

import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import mq from '../assets/MQU.png';
import Logo from '../components/Logo';
import InputComponent from '../components/InputComponent';

function RegisterPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [academicProgram, setAcademicProgram] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    axios.post('/users/register', { email, password, academicProgram })
      .then((response) => {
        // Show success message
        setSuccess('Registration successful! Redirecting to login...');
        setError(''); // Clear any existing error

        // Redirect to login after a short delay
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      })
      .catch(error => {
        console.error('Error registering:', error);
        setError(error.response.data.message || 'Registration failed');
        setSuccess(''); // Clear success message on error
      });
  };

  return (
    <article className="auth">
        <section className="left">
        <Logo $primary={true}/>
          <h1>Hi there!</h1>
          <p>Join the Macquarie<br></br>student community today!</p>
        <img src={mq} alt="App logo" className="mq-logo"/>
        </section>
        <section className="right">
        <div className="background" id="image2"></div>
        <h1>Register</h1>
        {success && <p className="success-msg">{success}</p>}
        {error && <p className="error-msg">{error}</p>}
        <form onSubmit={handleRegister}>
        <div>
            <InputComponent
              type="email"
              name="email"
              value={email}
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <InputComponent
              type="password"
              name="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <InputComponent
              type="text"
              name="academicProgram"
              value={academicProgram}
              placeholder="Academic Program"
              onChange={(e) => setAcademicProgram(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submitBtn"><p>Sign up</p></button>
        </form>
        <div className="sign">
          <h2>Already have an account?</h2>
          <Link to="/login">
            <h2>Log in</h2>
          </Link>
          </div>
          <p id="sign-info">Log in using your Macquarie student email!</p>
        </section>
    </article>
  );
}

export default RegisterPage;
