import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

let backendURL;

if (window.location.hostname === 'localhost') {
  // If running on localhost, use the local backend
  backendURL = 'http://localhost:5005';
} else {
  // If running in production, use the production backend
  backendURL = 'https://api.ireviewmq.com';
}

axios.defaults.baseURL = backendURL;
axios.defaults.withCredentials = true; // Include cookies

/* SOLUTION for 401 Unauthorized?: Using axios interceptors */
/*
axios.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response.status === 401) {
  //place your reentry code
 }
 return error;
});
*/


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();