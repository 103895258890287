// src/pages/HomePage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import CourseCard from "../components/CourseCard"
import Banner from "../components/Banner"
import bannerImage from "../assets/1cc.jpg"
import SortDropdown from '../components/SortDropdown';
import Searchbar from '../components/Searchbar';

function HomePage() {
  const [courses, setCourses] = useState([]);
  const [sortCriteria, setSortCriteria] = useState('overallRating'); // Default sort by overallRating


  useEffect(() => {
    fetchCourses();
  }, [sortCriteria]);

  const fetchCourses = () => {
    axios
      .get('/courses')
      .then((response) => {
        let sortedCourses = response.data.sort(
          (a, b) => b[sortCriteria] - a[sortCriteria]
        );
        setCourses(sortedCourses);
      })
      .catch((error) => {
        console.error('Error fetching courses:', error);
      });
  };



  const handleSortSelection = (sortValue) => {
    console.log('Sorted by:', sortValue);
    // NEED TO IMPLEMENT: Update the sortCriteria state with the selected value
  };

  return (
    <main>
      <article className='intro'>
        <Banner $image={bannerImage}>
          <div className='intro-banner'>
            <h1>Macquarie<br></br>Course Reviews</h1>
          </div>
        </Banner>
        <section className='intro-description'>
          <h5>Welcome to iReview!</h5>
          <h6>Getting ready to choose your next course at Macquarie University?<br></br>Find the perfect courses to match your academic goals.</h6>
        </section>
      </article>
      <hr id='all-courses' />
      <article className='content' id="courses">
        <section className='courses-header'>
          <h2>All Courses</h2>
          <div className='courses-options'>
            <Searchbar />
            <SortDropdown onSortChange={handleSortSelection} />
          </div>
        </section>
        <section className='courses-box'>
          {courses.map((course) => (
            <CourseCard
              key={course._id}
              course={course}
            />
          ))}
        </section>
      </article>
    </main >
  );
}

export default HomePage;