import { Link, useNavigate } from 'react-router-dom';

const ScrollLink = ({ to, children, ...props }) => {
    const navigate = useNavigate();
  
    const handleClick = (event) => {
      // Prevent default link behavior
      event.preventDefault();
  
      // Check if the current location's pathname is the same as the `to` prop's pathname
      const targetPath = to.split('#')[0];
      const currentPath = window.location.pathname;
  
      // Scroll to the element identified by the hash if we're already on the correct page
      if (currentPath === targetPath || targetPath === '/') {
        const hash = to.split('#')[1];
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          return;
        }
      }
  
      // Navigate to the new route with hash and then ensure scroll to the element
      navigate(to);
    };
  
    return (
      <Link to={to} onClick={handleClick} {...props}>
        {children}
      </Link>
    );
  };
  
  export default ScrollLink;