import styled from 'styled-components';

// Styles for the stars
const Star = styled.span`
  cursor: pointer;
  color: ${props => props.isActive ? '#ffc107' : '#e4e5e9'};
  font-size: 2em;
`;

const StarRating = ({ count, onChange, rating }) => {
  return (
    <div>
      {[...Array(count)].map((_, i) => (
        <Star
          key={i}
          onClick={() => onChange(i + 1)}
          isActive={rating > i}
        >
          ★
        </Star>
      ))}
    </div>
  );
};

export default StarRating;