// src/pages/AddCoursePage.js

import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import PageTitle from '../components/PageTitle';
import Button from '../components/Button';
import InputComponent from '../components/InputComponent';

function AddCoursePage() {
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  if (!user) {
    navigate('/login');
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        '/courses',
        { code, name, description },
        { withCredentials: true }
      )
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error('Error adding course:', error);
        setError(error.response.data.message || 'Failed to add course');
      });
  };

  return (
    <main>
      <PageTitle title="Add New Course" />
      <article className="content" id="add-course">
        <h5 className="form-title">Course Details</h5>
        {error && <p className='error-msg'>{error}</p>}
        <form onSubmit={handleSubmit}>
          <section className="add-form">
          <label className="course-form-label">Unit Information</label>
            <div className='unit-info'>
            <InputComponent $primary={true}
              type="text"
              name="code"
              value={code}
              placeholder="Code"
              onChange={(e) => setCode(e.target.value)}
              required
              pattern="[A-Za-z]{4}+[0-9]{4}"
              maxLength="8"
              style={{ textTransform: 'uppercase' }}
              />
              <InputComponent $primary={true}
               type="text"
               name="name"
               value={name}
               placeholder="Name"
               onChange={(e) => setName(e.target.value)}
               required
               style={{ textTransform: 'capitalize' }}
               />
            </div>

              <label className="course-form-label">Description</label>
              <InputComponent $primary={true}
                type="textarea"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                />
          </section>
          <section className="form-btn">
            <button type="submit">
              <Button $primary={true} label="Submit" />
            </button>
          </section>
        </form>
      </article>
    </main>
  );
}

export default AddCoursePage;