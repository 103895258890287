import styled from 'styled-components';
import React, { useEffect } from 'react';

const SearchContainer = styled.div`
    padding: 1em;
    width: 100%; 
    display: flex;
    justify-content: center;
`;


// const Search = styled.input`
//     width: 25vw;
//     padding: 0.4em 1em;
//     border-radius: var(--border-radius-small);
//     border: none;
//     box-shadow: var(--box-shadow-dark);
//     font-family: var(--font-text);
//     font-size: var(--font-size-small);
//     font-weight: var(--font-weight-medium);
//     color: var(--color-text);
//     background-color: white;
//     transition: border-color 0.2s ease-in-out;
//     outline: none;
    
//     &:focus {
//         border-color: var(--color-primary);
//     }

//     &::placeholder {
//         color: var(--color-placeholder);
//     }
//     `;

const Searchbar = ({ }) => {
    useEffect(() => {
        // Check if the script already exists to avoid multiple inserts
        if (!document.querySelector('script[src="https://cse.google.com/cse.js?cx=85f0b0dba2f144f70"]')) {
          const script = document.createElement('script');
          script.src = 'https://cse.google.com/cse.js?cx=85f0b0dba2f144f70'; // Replace with your Search Engine ID
          script.async = true;
          document.body.appendChild(script);
        }
    
        return () => {
          const script = document.querySelector('script[src="https://cse.google.com/cse.js?cx=85f0b0dba2f144f70"]');
          if (script) document.body.removeChild(script); // Cleanup on unmount
        };
      }, []);

    return (
        <SearchContainer>
            {/* <Search
                type="text"
                placeholder="🔎︎  Search for a course..."
                onChange={(e) => console.log(e.target.value)}
            /> */}
            <div className="gcse-search"></div>

        </SearchContainer>
    );
}

export default Searchbar;