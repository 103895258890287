import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PageTitle from '../components/PageTitle';
import Button from '../components/Button';

const CourseDetailsPage = () => {
    const { course_id } = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [expandedCourses, setExpandedCourses] = useState({});
    const [loadingReviews, setLoadingReviews] = useState({});
    const [generatingSummary, setGeneratingSummary] = useState({});

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await axios.get(`/courses/${course_id}`);
                setCourse(response.data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchCourse();
    }, [course_id]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const toggleReviews = (courseId) => {
        const isExpanded = expandedCourses[courseId];
        setExpandedCourses(prev => ({ ...prev, [courseId]: !isExpanded }));

        if (!isExpanded) {
            setLoadingReviews(prev => ({ ...prev, [courseId]: true }));
            axios.get(`/reviews/course/${courseId}`)
                .then(response => {
                    setCourse(prevCourse => ({ ...prevCourse, reviews: response.data }));
                })
                .catch(error => {
                    console.error('Error fetching reviews:', error);
                })
                .finally(() => {
                    setLoadingReviews(prev => ({ ...prev, [courseId]: false }));
                });
        }
    };

    const generateSummary = (courseId) => {
        setGeneratingSummary(prev => ({ ...prev, [courseId]: true }));
        axios.post(`/courses/${courseId}/summary`)
            .then(response => {
                setCourse(prevCourse => ({ ...prevCourse, summary: response.data.summary }));
            })
            .catch(error => {
                console.error('Error generating summary:', error);
            })
            .finally(() => {
                setGeneratingSummary(prev => ({ ...prev, [courseId]: false }));
            });
    };

    const getSentimentStyle = (sentiment) => {
        switch (sentiment) {
            case 'positive': return { color: 'green' };
            case 'negative': return { color: 'red' };
            case 'neutral': return { color: 'gray' };
            default: return { color: 'gray' }; // Default style for undefined sentiment
        }
    };

    return (
        <main>
            <PageTitle title="Course Details" />
            {course ? (
                <article className='content' id='course-details'>
                    <section className="course-overview">
                        <h2>{course.code}</h2>
                        <h1>{course.name}</h1>
                        <p>{course.description}</p>
                        
                        <div className='course-summary'>
                                <Button $primary={false}
                                    label="Generate Summary"
                                    onClick={() => generateSummary(course._id)} />
                                {course.summary && <p style={{ fontStyle: 'italic' }}>{course.summary}</p>}
                                {generatingSummary[course._id] && <p>Generating summary...</p>}
                            </div>

                    </section>
                    <section className='course-reviews'>
                    <ul className='course-rating'>
                            <li>Overall Rating: {course.overallRating.toFixed(2)}</li>
                            <li>Difficulty Level: {course.difficultyLevel.toFixed(2)}</li>
                            <li>Teaching Quality: {course.teachingQuality.toFixed(2)}</li>
                            <li>Workload: {course.workload.toFixed(2)} hrs/wk</li>
                        </ul>

                        <div className='course-buttons'>

                            <div className='review-btn'>
                                <Button $primary={expandedCourses[course._id]}
                                    label={expandedCourses[course._id] ? 'Hide Reviews' : 'Show Reviews'}
                                    onClick={() => toggleReviews(course._id)}
                                />
                            </div>
                            {expandedCourses[course._id] && (
                                <div className='courses-reviews'>
                                    {loadingReviews[course._id] ? (
                                        <p>Loading reviews...</p>
                                    ) : course.reviews && course.reviews.length > 0 ? (
                                        <ul>
                                            {course.reviews.map((review) => (
                                                <li key={review._id}>
                                                    <p>{review.text}</p>
                                                    <p>
                                                        Sentiment:
                                                        {review.sentiment ? (
                                                            <span style={getSentimentStyle(review.sentiment)}>
                                                                {review.sentiment}
                                                            </span>
                                                        ) : (
                                                            <span style={{ color: 'gray' }}>Not Available</span>
                                                        )}
                                                    </p>
                                                    <p className='courses-reviews-date'>
                                                        {new Date(review.date).toLocaleDateString()}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>No reviews yet.</p>
                                    )}
                                </div>
                            )}
                        </div>
                    </section>
                </article>
            ) : (
                <div>No course found.</div>
            )}
        </main>
    );
};

export default CourseDetailsPage;