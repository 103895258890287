import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import styled from 'styled-components';
import Button from "./Button"
import Logo from "./Logo"
import ScrollLink from './ScrollLink';

const NavContainer = styled.nav `
  display: flex;
  justify-content: space-between;
  position: fixed;

  width: 100vw;
  height: 60px;
  padding: 4px 0;

  top: 0;
  z-index: 5;
  color: white;
  font-family: var(--font-body);
  background-color: var(--primary-color);
`

const NavItem = styled.div `
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-right: 60px;
`


function Navbar() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);

  const handleLogout = () => {
    axios.get('/users/logout', { withCredentials: true })
      .then(() => {
        setUser(null);
        navigate('/login'); // Redirect to the login page
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  return (
    <NavContainer>
      <NavItem> {/* left-aligned items */}
        <Logo $primary={false} />
        <ScrollLink to="/#all-courses" className="nav-link">All Courses</ScrollLink>
        <a href="https://www.mq.edu.au/" target="_blank" className="nav-link">MQ Website</a>
        <a href="https://unitguides.mq.edu.au/" target="_blank" className="nav-link">Unit Guide</a>
      </NavItem>
      <NavItem> {/* right-aligned items */}
        {user ? (
          <>
            <Link to="/my-reviews" className="nav-link" id="nav-link-right">My Account</Link>
            <button onClick={handleLogout}><Button $primary={false} label="Log out" /></button>
          </>
        ) : (
          <>
            <Link to="/login"><Button $primary={true} $shadow="none" label="Login" /></Link>
            <Link to="/register"><Button $primary={false} label="Register" /></Link>
          </>
        )}
      </NavItem>
    </NavContainer>
  );
}

export default Navbar;