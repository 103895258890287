import React from 'react';
import Dropdown from './Dropdown';

function CourseDropdown({ courses, selectedCourseId, onCourseChange }) {
    const courseOptions = courses.map(course => ({
        _id: course._id,
        display: `${course.code}: ${course.name}`
    }));

    const containerStyle = {
    }

    const buttonStyle = {
        padding: "0.3em 1em",
    }

    const listStyle = {
        position: "absolute",
        width: "calc(70vw - 5em + 3px)",
        top: "calc(7em - 14px)",
    }

    const itemStyle = {
        padding: "0.7em 1em",
    }


    return (
        <Dropdown
            options={courseOptions}
            value={selectedCourseId}
            onChange={(e) => onCourseChange(e.target.value)}
            valueField="_id"
            displayField="display"
            $primary = {true}
            containerStyle={containerStyle}
            buttonStyle={buttonStyle}
            listStyle={listStyle}
            itemStyle={itemStyle}
        />
    );
}

export default CourseDropdown;