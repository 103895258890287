import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import pattern0 from '../assets/patterns.png';
import pattern1 from '../assets/pattern1.jpg';
import pattern2 from '../assets/pattern2.png';
import pattern3 from '../assets/pattern3.png';
import pattern4 from '../assets/pattern4.png';
import pattern5 from '../assets/pattern5.png';
import pattern6 from '../assets/pattern6.png';
import pattern7 from '../assets/pattern7.JPG';
import pattern8 from '../assets/pattern8.JPG';
import pattern9 from '../assets/pattern9.jpg';

const patterns = [pattern0, pattern1, pattern2, pattern3, pattern4, pattern5, pattern6, pattern7, pattern8, pattern9];

const Card = styled.div`
display: block;
margin: 1em;
width: auto;
height: auto;
  overflow-y: hidden;
  transition: transform 0.3s ease-in-out;
  &:hover {
  transform: scale(1.05);
}

`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: calc( 70vw/4 );
  margin: 1vw;
  margin-top: 0;
  padding: 1em;
  border-radius: 0 0 12px 12px;
  box-shadow: var(--box-shadow-dark);
  text-align: center;
  background-color: white;

  @media (max-width: 1900px) {
  width: calc( 70vw/2.5 );
    }

      @media (max-width: 1400px) {
        width: calc( 70vw/2.7 );
      padding: 0.5em;
    }

        @media (max-width: 1000px) {
  width: calc( 70vw - 4em );
          padding: 1em;
    }
`;

const CardImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  position: relative;
  left: 0;
  width: calc( 70vw/4 );
  height: 4em;
  margin: 1vw;
  margin-bottom: 0;
  padding: 1em;
  padding-bottom: 0;
  border-radius: 12px 12px 0 0;
  box-shadow: var(--box-shadow-dark);

  background-image: url(${props => props.pattern});
  background-size: cover;
  background-repeat: repeat;

  @media (max-width: 1900px) {
  width: calc( 70vw/2.5 );
    }

      @media (max-width: 1400px) {
        width: calc( 70vw/2.7 );
      padding: 0.5em;
    }

    @media (max-width: 1000px) {
  width: calc( 70vw - 4em );
        padding: 1em;
    }


`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 6em;
`;

const CardTitle = styled.h3`
  font-family: var(--font-heading);
  font-size: var(--font-size-label);
  font-weight: var(--font-weight-bolder);
  color: var(--color-label);
  text-transform: uppercase;
`;

const CardSubtitle = styled.h4`
  display: -webkit-box;        
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 2;
  width: 100%;
  font-family: var(--font-heading);
  font-size: var(--font-size-label);
  font-weight: var(--font-weight-bold);
  color: var(--color-text);
  text-transform: Capitalize;

  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
`;

const CardText = styled.p`
  color: var(--color-light);
  font-weight: var(--font-weight-bold);
  overflow: hidden;

    @media (max-width: 1400px) {
      font-size: var(--font-size-small);
    }

        @media (max-width: 1000px) {
      font-size: var(--font-size-base);
    }
`;

const CardList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1em;
  text-align: left;
  width: 100%;
  margin: 1em 0;
  padding: 0;

  @media (max-width: 1600px) {
  display: flex;
  flex-direction: column;
    }

      @media (max-width: 1000px) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1em;
    }
  
`;

const ListItem = styled.li`
  padding: 0.5em;
  border-radius: 5px;
  font-weight: 500;
  font-stretch: expanded;
  color: #666;
  background-color: #f8f8f8;

  @media (max-width: 1600px) {
  display: flex;
flex-direction: row;
justify-content: space-between;
    width: 100%;
  }

  @media (max-width: 1400px) {
      font-size: var(--font-size-small);
    }

            @media (max-width: 1000px) {
      font-size: var(--font-size-base);
    }
`


const CourseCard = ({ course }) => {
  const pattern = patterns[Math.floor(Math.random() * patterns.length)];
  return (
    <Link to={`/courses/${course._id}`}>
      <Card>
        <CardImage pattern={pattern} />
        <CardContainer>
          <CardHeader>
            <CardTitle>{course.code}</CardTitle>
            <CardSubtitle>{course.name}</CardSubtitle>
          </CardHeader>
          <CardList>
            <ListItem>Overall Rating<CardText>⭐ {course.overallRating.toFixed(2)}</CardText></ListItem>
            <ListItem>Difficulty Level<CardText>⭐ {course.difficultyLevel.toFixed(2)}</CardText></ListItem>
            <ListItem>Teaching Quality<CardText>⭐ {course.teachingQuality.toFixed(2)}</CardText></ListItem>
            <ListItem>Workload<CardText>{course.workload.toFixed(2)} hrs/wk</CardText></ListItem>
          </CardList>
        </CardContainer>
      </Card>
    </Link>
  );
}

export default CourseCard;