// src/pages/LoginPage.js

import React, { useState, useContext } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import mq from '../assets/MQU.png';
import Logo from '../components/Logo';
import InputComponent from '../components/InputComponent';

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post("/users/login", { email, password }, { withCredentials: true })
      .then((response) => {
        setUser(response.data.user);
        navigate("/my-reviews");
      })
      .catch((error) => {
        console.error("Error logging in:", error);
        setError(error.response.data.message || "Login failed");
      });
  };

  const backendURL = window.location.hostname === 'localhost' ?
    'http://localhost:5005' : 'https://api.ireviewmq.com';

  const handleGoogleLogin = () => {
    window.location.href = `${backendURL}/users/auth/google`;
  };

  return (
    <article className="auth">
      <section className="left">
        <Logo $primary={true} />
        <h1>Discover<br></br>Macquarie<br></br>University</h1>
        <p>Your guide to better course decisions<br></br>—with real student feedback</p>
        <img src={mq} alt="App logo" className="mq-logo" />
      </section>
      <section className="right">
        <div className="background"></div>
        <h1>Welcome Back!</h1>
        {error && <p className="error-msg">{error}</p>}
        <form onSubmit={handleLogin}>
          <div>
            <InputComponent
              type="email"
              name="email"
              value={email}
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <InputComponent
              type="password"
              name="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submitBtn">
            <p>Log in</p>
          </button>
          <button className="submitBtn" id="googleBtn" onClick={handleGoogleLogin}>Login with Google</button>
        </form>
        <div className="sign">
          <h2>New Here?</h2>
          <Link to="/register">
            <h2>Register</h2>
          </Link>
        </div>
        <p id="sign-info">Sign up using your Macquarie student email!</p>
      </section>
    </article>
  );
}

export default LoginPage;
